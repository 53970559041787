import styled from 'styled-components';

export const Section = styled.section`
	width: 100%;
	height: fit-content;
	background-color: var(--background-color);
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&:nth-child(odd) {
		background-color: var(--secondary-color);
	}

	&:first-child {
		padding: 0;
	}
`;
