import React from 'react';
import {Hero, ServiceList, SplitTextImage, PortfolioGallery, Form, Testimonial, Button, ImageBlock} from 'components';
import RichText from "utils/richText";
import {Section} from "../components/common/section";

const SliceZone = ({ body }) => {

	const newArr = body.slice(0,2);

	const slice = (bodyContent, i) => {

		i = `${bodyContent.type} - ${i}`;

		switch(bodyContent.type){
			case "hero": return(
				<Hero	key={i}
				logo={bodyContent.primary.imageSharp.childImageSharp.fluid} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} image={bodyContent.primary.image} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action}
				/>
			)
			case "list_icon_info": return(
				<ServiceList key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} fields={bodyContent.fields}/>
			)
			case "split_text_image": return(
				<SplitTextImage key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} image={bodyContent.primary.image}/>
			)
			case "imagegallery": return(
				<PortfolioGallery key={i} fields={bodyContent.fields}/>
			)
			case "form": return(
				<Form key={i} name={bodyContent.primary.form_name} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} fields={bodyContent.fields} action={bodyContent.primary.action}></Form>
			)
			case "testimonial": return(
				<Testimonial key={i} testimonial={bodyContent.primary.testimonial} author={bodyContent.primary.author} image={bodyContent.primary.imageSharp}/>
			)
			case "call_to_action": 
				let target= bodyContent.primary.link.__typename === "PRISMIC__ExternalLink" ? "_blank" : "";
				if(bodyContent.primary.type === "fullWidth"){
					return(
						<Button key={i} to={bodyContent.primary.link.url || `/${bodyContent.primary.link._meta.uid}`} fullWidth target={target}>{bodyContent.primary.text}</Button>
						)
					}else{
					return(
						<Button key={i} to={bodyContent.primary.link.url || `/${bodyContent.primary.link._meta.uid}`} target={target}>{bodyContent.primary.text}</Button>
					)
				}
			case "image_block":
				return(
					<ImageBlock key={i} fluid={bodyContent.primary.imageSharp.childImageSharp.fluid} type={bodyContent.primary.type}/>
				)
			case "content_block":
				return (
					<RichText key={i} render={bodyContent.primary.content}/>
				)
			default: return(
				<h3 key={i} style={{textAlign: "center", margin: "30px 0"}} key={i}>Error Loading extra content: <span style={{color: "red"}}>{bodyContent.type || "undefined graphql entry"}</span></h3>	
			)
		}
	}

	return (
		<>
			{body.map((bodyContent, i) => {
				if(bodyContent.type === "hero" || bodyContent.type === "call_to_action" || bodyContent.type === "image_block" || bodyContent.type === "content_block"){
					return(
						<>
							{slice(bodyContent, i)}
						</>
					)
				}else{
					return(
						<Section key={`section-${i}`}>
							{slice(bodyContent, i)}
						</Section>
					)
				}
			})}
		</>
	);
};

export default SliceZone;