import React from 'react';
import { graphql } from 'gatsby';
import RichText from 'utils/richText';
import styled from "styled-components";
import SliceZoneSwitch from 'utils/sliceZoneSwitch';
import SEO from "utils/seo";

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 20px;
	padding-top: 20px;
	padding-bottom: 30px;
	background-color: var(--background-color);
  color: white;
  text-align: left;
	max-width: 1300px;
	margin: 0 auto;

		> .gatsby-image-wrapper{
			max-width: 800px;
			height: fit-content;
			margin: 0 auto;
			margin-bottom: 30px;

			>div {
				padding-bottom: 66%;
			}
		}

    > h1 {
			font-family: "Noto Sans JP";
			font-size: var(--landing-fontSize);
			line-height: 3.5rem;
			margin: 0 0 30px 0;
			
			@media (max-width: 600px){
				line-height: 2.5rem;
			}
    }

		>h2 {
			font-size: var(--heading-fontSize);
			line-height: 2rem;
			border-top: 1px solid white;
			margin-top: 0;
			padding: 30px 10px;
		}

		>h3 {
      margin: 30px 20px 20px 20px;
			padding: 10px 5px;
			font-size: var(--subHeading-fontSize);
			line-height: 1.8rem;
			background-color: rgba(0,0,0,0.1);
		}

		>p {
			margin-bottom: 20px;
			padding: 0 20px;
			line-height: 33px;
			font-size: var(--body-fontSize);

			a {
				color: var(--primary-color);
					padding: 5px;

				&:hover {
					background-color: white;

					&::after{
					content: " ↗";
					}
				}
			}
		}
`;

export const query = graphql`
	query PageQuery($id: String) {
		prismic {
			allPages(id: $id) {
				edges {
					node {
						seo_title
						seo_description
						page_title
						_meta {
							uid
							id
						}
						body {
							... on PRISMIC_PageBodyCall_to_action {
								type
								label
								primary {
									type
									text
									link {
										... on PRISMIC_Page {
											_meta {
												uid
											}
										}
									}
								}
							}
							... on PRISMIC_PageBodyImagegallery {
								type
								label
								fields {
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
									link {
										... on PRISMIC_Portfolio_piece {
											_meta {
												uid
											}
											page_title
										}
									}
								}
							}
							... on PRISMIC_PageBodyForm {
								type
								label
								primary {
									form_name
									heading
									subheading
									description
									action {
										... on PRISMIC_Page {
											_meta {
												uid
											}
										}
									}
								}
								fields {
									required
									field_type
									field_name
								}
							}
							... on PRISMIC_PageBodyTestimonial {
								type
								label
								primary {
									testimonial
									author
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
							... on PRISMIC_PageBodyImage_block {
								type
								label
								primary {
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
							... on PRISMIC_PageBodyContent_block {
								type
								label
								primary {
									content
								}
							}
						}
					}
				}
			}
		}
	}
`;

const Page = ({ data }) => {
	if(!data){
		return null;
	}

	const pageTitle = data?.prismic?.allPages?.edges[0]?.node?.page_title;
	const body = data?.prismic?.allPages?.edges[0]?.node.body;

	return (
		<>
		<SEO title={data.prismic.allPages.edges[0].node.seo_title} description={data.prismic.allPages.edges[0].node.seo_description}/>
		<PageWrapper>
			{!!pageTitle && <RichText render={pageTitle}/>}
			{!!body && <SliceZoneSwitch body={body}/>}
		</PageWrapper>
		</>
	);
};

export default Page;
